import { useMemo, useState } from "react"

import { homeEventFilterOptions } from "src/data/events/logic/homeEventConstants"
import { THomeEventFilterGroup } from "src/data/events/types/homeEventTypes"
import { useFeatureAvailability } from "src/data/featureAvailability/logic/useFeatureAvailability"
import { useTranslate } from "src/i18n/useTranslate"
import { DropdownMultiSelect } from "src/ui/DropdownSelect/DropdownMultiSelect"

type HomeLogEventFilterDropdownProps = {
  loading?: boolean
  eventFilter: THomeEventFilterGroup[]
  setEventFilter: React.Dispatch<React.SetStateAction<THomeEventFilterGroup[]>>
}

export function HomeLogEventFilterDropdown({
  loading,
  eventFilter,
  setEventFilter,
}: HomeLogEventFilterDropdownProps) {
  const { _t, langKeys } = useTranslate()

  const cigaretteSmokeAvailable = useFeatureAvailability({
    feature: "cigarette_smoke_detection",
  }).available
  const sensorSwapAvailable = useFeatureAvailability({
    feature: "sensor_swap",
  }).available

  const [searchValue, setSearchValue] = useState("")

  const availableFilterGroups = useMemo(() => {
    // Limiting to 30 events in the events parameter so that
    // we do not exceed the BE character limit on the events parameter
    const canAddAdditionalFilter = eventFilter.length < 30
    const isChecked = (value: THomeEventFilterGroup) =>
      eventFilter.includes(value)

    return homeEventFilterOptions
      .filter((option) => {
        if (option.value === "smoking_detection") {
          return cigaretteSmokeAvailable
        }
        if (option.value === "sensor_swap") {
          return sensorSwapAvailable
        }
        return true
      })
      .map((e) => ({
        value: e.value,
        label: _t(e.label),
        disabled: !canAddAdditionalFilter && !isChecked(e.value),
      }))
      .sort((a, b) => a.label.localeCompare(b.label))
  }, [_t, cigaretteSmokeAvailable, eventFilter, sensorSwapAvailable])

  const dropdownOptions = useMemo(() => {
    return availableFilterGroups.filter((option) =>
      option.label.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
    )
  }, [searchValue, availableFilterGroups])

  return (
    <DropdownMultiSelect
      label={_t(langKeys.filter_event_type)}
      placeholder={_t(langKeys.search)}
      options={dropdownOptions}
      selectedValues={eventFilter}
      onChange={({ checked, option }) => {
        if (checked) {
          setEventFilter((prev) => [...prev, option.value])
        } else {
          setEventFilter((prev) => prev.filter((o) => o !== option.value))
        }
      }}
      onSearch={(s) => setSearchValue(s)}
      onClearSelection={() => setEventFilter([])}
      loading={loading}
    />
  )
}
